import React, { useState, useContext } from 'react';
import { Icon, Button } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import { FirebaseContext, AuthContext } from '../../context';
import Loading from '../../components/Loading/Loading';
import Error from '../../components/Error/Error';
import { useMutation } from '@apollo/react-hooks';
import ADD_USER from '../../graphql/mutation/addUser';
import formatAddress from '../../util/formatAddress';
import capitalize from '../../util/capitalize';
import { v4 } from 'uuid';

const styles = {
  svg: {
    height: '18px',
    width: '18px',
    fill: 'currentcolor',
    marginRight: '10px',
  },
};

const SignUp = ({ open, closeModal, openLogin, state, getUser, toRoute }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const Firebase = useContext(FirebaseContext);
  const Auth = useContext(AuthContext);
  const history = useHistory();
  const [addUser, { data }] = useMutation(ADD_USER);

  const createUser = async ({ firstName, lastName, email, type, auth }) => {
    try {
      let user = {
        id: auth,
        firstName: capitalize(firstName),
        lastName: capitalize(lastName),
        email: email.toLowerCase(),
        type,
        auth,
      };
      if (state.address) {
        const { id, street, city, province, country, postalCode, lat, long } = formatAddress(state.address);
        user.addressId = id;
        user.street = street;
        user.city = city;
        user.province = province;
        user.country = country;
        user.postalCode = postalCode;
        user.lat = lat;
        user.long = long;
      } else {
        user.addressId = v4();
      }
      
      const newUser = await addUser({ variables: user });

      if (newUser.data.addNewUser.success) {
        getUser({variables: {id: auth}});
        Auth.setState('id', auth);
        Auth.setState('email', user.email);
        Auth.setState('firstName', user.firstName);
        Auth.setState('type', type);
        localStorage.setItem('id', auth);
        localStorage.setItem('email', user.email);
        localStorage.setItem('firstName', user.firstName);
        localStorage.setItem('type', type);
        setLoading(false);
        closeModal();
        history.push(toRoute);
      } else {
        setLoading(false);
        setError(newUser.data.addNewUser.message);
      } 
    } catch (err) {
      setLoading(false);
      setError(err.message);
    }
  };

  const signUpWithGoogle = async () => {
    try {
      const res = await Firebase.signInWithGoogle();
      const { given_name, family_name, email } = res.additionalUserInfo.profile;
      const { uid } = res.user;
  
      createUser({
        firstName: given_name,
        lastName: family_name, 
        email, 
        type: state.type, 
        auth: uid,
      });
    } catch (err) {
      setLoading(false);
      setError(err.message);
    }
  };

  const signUpWithEmail = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      setError(false);
      const signupRes = await Firebase.createUserWithEmailAndPassword(email, password);
      const uid = signupRes.user.uid;

      createUser({
        firstName,
        lastName,
        email, 
        type: state.type, 
        auth: uid,
      });
    } catch (err) {
      console.log(err);
      setLoading(false);
      setError(err.message);
    }
  }

  return(
    <div className={open ? 'overlay' : 'hide'}>
      <div className='modal'>
        {loading && <Loading />}
        <Icon onClick={closeModal} id='close-modal-button' style={styles.icon}>close</Icon>
        <div className="auth-body">
          <Button variant='outlined' id="button-google" onClick={signUpWithGoogle}>
            <svg
              viewBox="0 0 18 18"
              style={styles.svg}
            >
              <path
                d="M9 3.48c1.69 0 2.83.73 3.48 1.34l2.54-2.48C13.46.89 11.43 0 9 0 5.48 0 2.44 2.02.96 4.96l2.91 2.26C4.6 5.05 6.62 3.48 9 3.48z"
                fill="#EA4335"
              />
              <path
                d="M17.64 9.2c0-.74-.06-1.28-.19-1.84H9v3.34h4.96c-.1.83-.64 2.08-1.84 2.92l2.84 2.2c1.7-1.57 2.68-3.88 2.68-6.62z"
                fill="#4285F4"
              />
              <path
                d="M3.88 10.78A5.54 5.54 0 0 1 3.58 9c0-.62.11-1.22.29-1.78L.96 4.96A9.008 9.008 0 0 0 0 9c0 1.45.35 2.82.96 4.04l2.92-2.26z"
                fill="#FBBC05"
              />
              <path
                d="M9 18c2.43 0 4.47-.8 5.96-2.18l-2.84-2.2c-.76.53-1.78.9-3.12.9-2.38 0-4.4-1.57-5.12-3.74L.97 13.04C2.45 15.98 5.48 18 9 18z"
                fill="#34A853"
              />
            </svg>
            <span className="button-text">Sign up with Google</span>
          </Button>
          <div className="or-divider-wrapper">
            <span className="or-divider">
              <span>or</span>
            </span>
          </div>
          {error && <Error error={error} />}
          <form className="auth-form">
            <label>First Name</label>
            <input value={firstName} onChange={e => setFirstName(e.target.value)} required type="first name"/>
            <label>Last Name</label>
            <input value={lastName} onChange={e => setLastName(e.target.value)} required type="last name"/>
            <label>Email</label>
            <input value={email} onChange={e => setEmail(e.target.value)} required type="email"/>
            <label>Password</label>
            <input value={password} onChange={e => setPassword(e.target.value)} required type="password" />
            <Button 
              variant="contained" 
              color="secondary"
              type='submit'
              onClick={signUpWithEmail}
            >
              Sign up with email
            </Button>
          </form>
          <div className='auth-footer'>
            <p className='margin-top-20'>
              {`Already have an account? `}
              <span className='link' onClick={() => {closeModal(); openLogin()}}>Log In</span>
            </p>
          </div>
          </div>
      </div>
    </div>
  )
}

export default SignUp;