import React, { useContext } from 'react';
import { AuthContext } from '../../context';
import { useQuery } from '@apollo/react-hooks';
import GET_BOOKINGS_BY_USER from '../../graphql/query/bookingsByUser';
import BookingCard from './BookingCard';
import './dashboard.css';


const Dashboard = () => {
  const Auth = useContext(AuthContext);
  console.log(Auth.id);
  const { data, loading } = useQuery(GET_BOOKINGS_BY_USER, {
    variables: {
      userId: '7niSD9Qt2DUH79SFv7LwbZ8IaNr2',
    }
  });

  console.log(data);

  if (loading) {
    return '...'
  }
  return (
    <div id='dashboard'>
      <h1>Mama Mobile</h1>
      <h4>Upcoming Bookings:</h4>
      <div className='card-wrapper'>
        {data.bookingsByUser.map(booking => {
          if (booking.bookingStatus === 'BOOKED') {
            return <BookingCard booking={booking} />
          }
        })}
      </div>
      <h4>Past Bookings:</h4>
      <div className='card-wrapper'>
        {data.bookingsByUser.map(booking => {
          if (booking.bookingStatus === 'ARRIVED') {
            return <BookingCard past booking={booking} />
          }
        })}
      </div>
    </div>
  )
}

export default Dashboard;