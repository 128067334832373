const timeMap = [
  '7:00am', '7:15am', '7:30am', '7:45am', '8:00am', '8:15am', '8:30am', '8:45am',
  '9:00am', '9:15am', '9:30am', '9:45am', '10:00am', '10:15am', '10:30am', '10:45am',
  '11:00am', '11:15am', '11:30am', '11:45am', '12:00pm', '12:15pm', '12:30pm', '12:45pm',
  '1:00pm', '1:15pm', '1:30pm', '1:45pm', '2:00pm', '2:15pm', '2:30pm', '2:45pm',
  '3:00pm', '3:15pm', '3:30pm', '3:45pm', '4:00pm', '4:15pm', '4:30pm', '4:45pm',
  '5:00pm', '5:15pm', '5:30pm', '5:45pm', '6:00pm', '6:15pm', '6:30pm', '6:45pm',
  '7:00pm', '7:15pm', '7:30pm', '7:45pm', '8:00pm', '8:15pm', '8:30pm', '8:45pm',
  '9:00pm', '9:15pm', '9:30pm', '9:45pm', '10:00pm', '10:15pm', '10:30pm', '10:45pm',
  '11:00pm', '11:15pm', '11:30pm', '11:45pm'
];

const timeMapDisplay = [
  '7 am', '7:15 am', '7:30 am', '7:45 am', '8 am', '8:15 am', '8:30 am', '8:45 am',
  '9 am', '9:15 am', '9:30 am', '9:45 am', '10 am', '10:15 am', '10:30 am', '10:45 am',
  '11 am', '11:15 am', '11:30 am', '11:45 am', '12 pm', '12:15 pm', '12:30 pm', '12:45 pm',
  '1 pm', '1:15 pm', '1:30 pm', '1:45 pm', '2 pm', '2:15 pm', '2:30 pm', '2:45 pm',
  '3 pm', '3:15 pm', '3:30 pm', '3:45 pm', '4 pm', '4:15 pm', '4:30 pm', '4:45 pm',
  '5 pm', '5:15 pm', '5:30 pm', '5:45 pm', '6 pm', '6:15 pm', '6:30 pm', '6:45 pm',
  '7 pm', '7:15 pm', '7:30 pm', '7:45 pm', '8 pm', '8:15 pm', '8:30 pm', '8:45 pm',
  '9 pm', '9:15 pm', '9:30 pm', '9:45 pm', '10 pm', '10:15 pm', '10:30 pm', '10:45 pm',
  '11 pm', '11:15 pm', '11:30 pm', '11:45 pm'
];

const timeMap24 = [
  '7:00', '7:15', '7:30', '7:45', '8:00', '8:15', '8:30', '8:45',
  '9:00', '9:15', '9:30', '9:45', '10:00', '10:15', '10:30', '10:45',
  '11:00', '11:15', '11:30', '11:45', '12:00', '12:15', '12:30', '12:45',
  '13:00', '13:15', '13:30', '13:45', '14:00', '14:15', '14:30', '14:45',
  '15:00', '15:15', '15:30', '15:45', '16:00', '16:15', '16:30', '16:45',
  '17:00', '17:15', '17:30', '17:45', '18:00', '18:15', '18:30', '18:45',
  '19:00', '19:15', '19:30', '19:45', '20:00', '20:15', '20:30', '20:45',
  '21:00', '21:15', '21:30', '21:45', '22:00', '22:15', '22:30', '22:45',
  '23:00', '23:15', '23:30', '23:45'
];

export {
  timeMap,
  timeMapDisplay,
  timeMap24,
};