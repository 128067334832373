import React from 'react';
import './Error.css';
import { Icon } from '@material-ui/core';

const Error = ({ error }) => (
  <div className='error'>
    <Icon>error_outline</Icon>
    <p>{error}</p>
  </div>
);

export default Error;