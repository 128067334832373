import { gql } from "apollo-boost";

const GET_AVAILABILITY = gql`
  query Availability(
    $service: String
    $lat: String
    $long: String
    $pro: ID
  ) {
    availability(
      service: $service
      lat: $lat
      long: $long
      pro: $pro
    ) {
        schedules {
          id
          date
          time
          status
        }
        pros
      }
  }
`;

export default GET_AVAILABILITY;