import { gql } from "apollo-boost";

const GET_BOOKINGS_BY_USER = gql`
  query BookingsByUser($userId: ID!) {
    bookingsByUser(userId: $userId) {
      id
      date
      startTime
      duration
      bookingStatus
      paymentStatus
      subtotal
      promoValue
      tips
      tax
      total
      notes
      bookingServices {
        service {
          id
          name
          duration
        }
        user {
          id
          firstName
          email
          new
        }
      }
      pro {
        id
        proNumber
        firstName
      }
      user {
        id
        firstName
        new
        email
        tel
        relationships {
          id
          relationship
          child {
            firstName
            id
          }
        }
      }
    }
  }
`;

export default GET_BOOKINGS_BY_USER;