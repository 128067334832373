import React, { useState } from 'react';
import { timeMapDisplay } from '../../util/timeMap';
import { Icon, Button, ButtonGroup, IconButton } from '@material-ui/core';
import moment from 'moment';
import { Link } from 'react-router-dom';

const BookingCard = ({ booking, past }) => {
  const { date, startTime, pro, duration } = booking;

  return (
    <div className='booking-card'>
      <div className='card-item'>
        <Icon>calendar_today_outline</Icon>
        <p>{moment(date).format("MMM D YYYY")}</p>
      </div>
      <div className='card-item'>
        <Icon>schedule_outline</Icon>
        <p>{timeMapDisplay[startTime]}</p>
      </div>
      <div className='card-item'>
        <Icon>person_outline</Icon>
        <p>{pro.firstName}</p>
      </div>
      <p>Services:</p>
      {
        booking.bookingServices.map((bookingService => 
          <p>{`${bookingService.service.name} - ${bookingService.service.duration} min`}</p>
        ))
      }
      { past ?
        <ButtonGroup size="small" color="secondary">
          {/* <Link to={`/?pro=${pro.id}`}> */}
            <Button>
              {`Book with ${pro.firstName}`}
              </Button>
              {/* </Link> */}
          <Button>Email Receipt</Button>
        </ButtonGroup> :
        <ButtonGroup size="small" color="secondary">
          <Button>Reschedule</Button>
          <Button>Cancel</Button>
        </ButtonGroup>
      }
    </div>
  )
}

export default BookingCard;