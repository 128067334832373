import { gql } from "apollo-boost";

const GET_PROMO = gql`
  query Promo($id: ID!) {
    promo(id: $id) {
      id
      value
    }
  }
`;

export default GET_PROMO;