import { gql } from 'apollo-boost';

const NEW_REQUEST = gql`
  mutation NewRequest(
    $id: ID
    $firstName: String
    $lastName: String
    $email: String
    $tel: String
    $notes: String
    $services: String
    $paymentMethod: String
    $stage: String
    $addressId: String
    $street: String
    $city: String
    $province: String
    $country: String
    $postalCode: String
    $lat: String
    $long: String
    $date: String
    $startTime: Int
    $endTime: Int
  ) {
    newRequest(
      id: $id
      firstName :$firstName
      lastName: $lastName
      email: $email
      tel: $tel
      notes: $notes
      services: $services
      paymentMethod: $paymentMethod
      stage: $stage
      addressId: $addressId
      street: $street
      city: $city
      province: $province
      country: $country
      postalCode: $postalCode
      lat: $lat
      long: $long
      date: $date
      startTime: $startTime
      endTime: $endTime
    ) {
      success
      message
    }
  }
`;

export default NEW_REQUEST;