import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import './App.css';
import './components/Card/card.css';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import ApolloClient from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';
import { InMemoryCache } from 'apollo-cache-inmemory';
import theme from './theme.js';
import firebase from './Firebase';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { AuthContext, FirebaseContext } from './context';
import Landing from './pages/Landing/Landing';
import Dashboard from './pages/Dashboard/Dashboard';

const client = new ApolloClient({
  cache: new InMemoryCache({
    dataIdFromObject: object => object.id || null,
  }),
  // uri: 'http://localhost:4000/graphql',
  uri: 'https://api.mamamobile.com/graphql',
});

const themeCustomize = createMuiTheme(theme);

const id = localStorage.getItem('id');
const email = localStorage.getItem('email');
const firstName = localStorage.getItem('firstName');
const type = localStorage.getItem('type');

const liveKey = "pk_live_7sEpuRtG8DSaZMKuKJpyJVXt00ea8K8vXR";
const testKey = 'pk_test_J2f0eCxkLH21jQEPy2ATpvry00lbzyYxh1';

const stripePromise = loadStripe(liveKey);

class App extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      id,
      email,
      firstName,
      type,
      setState: (key, value) => {
        this.setState({ [key]: value })
      }
    };

    this.firebase = firebase;
    // localStorage.clear();
  }

  render () {
    return (
      <Router>
        <Elements stripe={stripePromise}>
          <ApolloProvider client={client}>
            <AuthContext.Provider value={this.state}>
              <FirebaseContext.Provider value={this.firebase}>
                <ThemeProvider theme={themeCustomize}>
                <div>
                  <Switch>
                    <Route exact path="/" component={Landing} />
                    <Route path="/dashboard" component={Dashboard} />
                    <Route path="/select-service" component={Landing} />
                    <Route path="/massage-addons" component={Landing} />
                    <Route path="/first-visit" component={Landing} />
                    <Route path="/physio/first-visit" component={Landing} />
                    <Route path="/physio/booking" component={Landing} />
                    <Route path="/physio/booking/consultation" component={Landing} />
                    <Route path="/booking-request" component={Landing} />
                    <Route path="/request-confirmed" component={Landing} />
                    <Route path="/duration" component={Landing} />
                    <Route path="/address" component={Landing} />
                    <Route path="/not-available" component={Landing} />
                    <Route path="/schedule" component={Landing} />
                    <Route path='/covid' component={Landing} />
                    <Route path="/payment" component={Landing} />
                    <Route path="/thank-you-booking" component={Landing} />
                    <Route path="/:proId" component={Landing} />
                  </Switch>
                </div>
                </ThemeProvider>
              </FirebaseContext.Provider>
            </AuthContext.Provider>
          </ApolloProvider>
        </Elements>
      </Router>
    )
  }
}

export default App;
