import { gql } from 'apollo-boost';

const ADD_USER = gql`
  mutation AddNewUser(
    $id: ID
    $firstName: String
    $lastName: String
    $email: String
    $tel: String
    $addressId: ID
    $unit: String
    $street: String
    $city: String
    $province: String
    $country: String
    $postalCode: String
    $lat: String
    $long: String
    $auth: String
    $type: String
  ) {
    addNewUser(
      id: $id
      firstName: $firstName
      lastName: $lastName
      email: $email
      tel: $tel
      addressId: $addressId
      unit: $unit
      street: $street
      city: $city
      province: $province
      country: $country
      postalCode: $postalCode
      lat: $lat
      long: $long
      auth: $auth
      type: $type
    ) {
      success
      message
      user {
        id
        firstName
        lastName
        tel
        email
        stripe
        type
        new
        address {
          id
          street
          unit
          city
          province
        }
      }
    }
  }
`;

export default ADD_USER;