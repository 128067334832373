import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useLocation, useParams } from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
import './landing.css';
import Main from '../Main/Main';
import SelectService from '../SelectService/SelectService';
import FirstVisit from '../FirstVisit/FirstVisit';
import Duration from '../Duration/Duration';
import Addons from '../Addons/Addons';
import Location from '../Location/Location';
import Covid from '../Covid/Covid';
import { Fab } from '@material-ui/core';
import Login from '../Auth/Login';
import '../Auth/auth.css';
import SignUp from '../Auth/SignUp';
import PasswordReset from '../Auth/PasswordReset';
import Schedule from '../Schedule/Schedule';
import Payment from '../Payment/Payment';
import { AuthContext } from '../../context';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import GET_USER from '../../graphql/query/user';
// import HomeVsVirtual from '../HomeVsVirtual/HomeVsVirtual';
import GET_AVAILABILITY from '../../graphql/query/availability';
import processSchedule from '../../util/processSchedule';
import Loading from '../../components/Loading/Loading';
import { serviceMap } from '../../util/serviceMap';
import proMap from '../../util/proMap';
import NotAvailable from '../NotAvailable/NotAvailable';
import ThankYouBooking from '../ThankYou/ThankYouBooking';
import PhysioFirstVisit from '../Physio/FirstVisit';
import PhysioBooking from '../Physio/PhysioBooking';
import ThankYouRequest from '../ThankYou/ThankYouRequest';
import RequestMassage from '../RequestMassage/RequestMassage';
import { HistoryTwoTone } from '@material-ui/icons';


const initState = {
  '/': false,
};

function useQueryParam() {
  return new URLSearchParams(useLocation().search);
}

const Landing = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [signUpModal, setSignUpModal] = useState(false);
  const [passwordReset, setPasswordReset] = useState(false);
  const [type, setType] = useState('');
  const [duration, setDuration] = useState('');
  const [service, setService] = useState('');
  const [firstVisit, setFirstVisit] = useState(false);
  const [addons, setAddons] = useState([]);
  const [address, setAddress] = useState('');
  const [dateIdx, setDateIdx] = useState(0);
  const [time, setTime] = useState('');
  const [pro, setPro] = useState('');
  const [schedule, setSchedule] = useState([]);
  const [route, setRoute] = useState(initState);
  const [proSelected, setProSelected] = useState('');
  const { pathname } = history.location;
  const Auth = useContext(AuthContext);
  const [getUser, USER] = useLazyQuery(GET_USER);
  const [getAvailability, availability] = useLazyQuery(GET_AVAILABILITY);
  const [toRoute, setToRoute] = useState('/payment');

  const query = useQueryParam();
  const proId = query.get('proId');
  const queryStage = query.get('stage');
  console.log('auth', Auth);

  useEffect(() => {

    // if (Auth.id) {
    //   getUser({variables: {
    //     id: Auth.id
    //   }});
    // };

    // if (proId) {
    //   const proIdLower = proId.toLowerCase();
    //   const pro = Object.entries(proMap).filter(([ key, value ]) => {
    //     console.log(key, value);
    //     const name = value.lastName.toLowerCase();
    //     if (proIdLower === name) return value;
    //   });
    //   setProSelected(pro[0][0]);
    // }

    // if (availability.data && schedule.length === 0) {
    //   let totalDuration = duration;
    //   console.log(availability.data);

    //   if (availability.data.availability.schedules.length === 0) {
    //     setLoading(false);

    //     if (history.location.pathname === '/address') {
    //       if (availability.data.availability.pros.length === 0) {
    //         history.push('/not-available');
    //       } else {
    //         if (USER.data && USER.data.user) {
    //           history.push('/booking-request');
    //         } else {
    //           setToRoute('/booking-request');
    //           setSignUpModal(true);
    //         }
    //       }
    //     }
    //   } else {
    //     if (addons.length > 0) {
    //       addons.map(addon => {
    //         totalDuration += serviceMap[addon].duration;
    //       });
    //     }
  
    //     if (firstVisit && service === 'physio') {
    //       totalDuration = 60;
    //     };
  
    //     if (service === 'physio') {
    //       totalDuration += 15;
    //     } else {
    //       totalDuration += 60;
    //     }
  
    //     const schedule = processSchedule(availability.data.availability.schedules, totalDuration);
    //     setSchedule(schedule);
    //     setLoading(false);
    //     history.push('/schedule');
    //   }
    // }

    // if (availability.error) {
    //   setLoading(false);
    //   alert('Please select an address from the dropdown!', availability.error);
    // }

    // if (!type) {
    //   if (queryStage) {
    //     setType(queryStage);
    //   }
    // }

    // setRoute({
    //   ...initState,
    //   [pathname]: true,
    // })
  }, [pathname, availability]);
  
  const mainService = (service === 'rmt') ? `${service}_${type}_${duration}` : (firstVisit ? `${service}_60` : `${service}_${duration}`);

  const selectStage = (value) => {
    setType(value);
    history.push('/select-service');
  };

  const selectService = (value) => {
    setService(value);
    if (value === 'rmt') {
      history.push('/covid');
    } else if (value === 'therapy') {
      window.open('https://www.alli.io/therapy?utm_source=mamamobile&utm_medium=website&utm_campaign=selectService', '_blank');
    } else {
      history.push('/first-visit');
    }
  };

  const selectFirstVisit = (value) => {
    setFirstVisit(value);
    if (service === 'rmt') {
      history.push('/duration');
    } else {
      if (value) {
        setDuration(60);
        history.push('/physio/first-visit');
        // setLoading(true);
        // getAvailability({ variables: {
        //   service,
        //   pro: proSelected,
        // }});
      } else {
        setDuration(undefined);
        history.push('/physio/booking');
      }
    }
  };

  const selectDuration = (value) => {
    setDuration(value);
    if (service === 'rmt') {
      history.push('/massage-addons');
    } else {
      history.push('/physio/booking');
      // getAvailability({ variables: {
      //   service,
      //   pro: proSelected,
      // }});
    }
  };

  const selectAddons = (value) => {
    setAddons(value);
    if (proSelected) {
      getAvailability({ variables: {
        service,
        pro: proSelected,
      }});
    } else {
      history.push('/address');
    }
  };

  const selectAddress = () => {
    setLoading(true);
    // setSchedule([]);
    if (!address.geometry) {
      setLoading(false);
      alert('please enter your location where you would like your massage and select an address from the drop down!');
    } else {
      getAvailability({ variables: {
        lat: address.geometry.location.lat().toString(),
        long: address.geometry.location.lng().toString(),
        service,
        pro: proSelected,
      }});
      // history.push('/schedule');
    }
  }

  const state = {
    type,
    duration,
    address,
    service,
    addons,
    firstVisit,
  };

  console.log('state', state);
  console.log('schedule', schedule);
  console.log('a', availability);

  return (
    <div className='landing'>
      {loading && <Loading />} 
      {loginModal && <Login 
        open={loginModal} 
        getUser={getUser}
        user={USER ? USER : {}}
        closeModal={() => setLoginModal(false)} 
        openSignUp={() => setSignUpModal(true)}
        openPasswordReset={() => setPasswordReset(true)} 
        state={state}
        toRoute={toRoute}
      />}
      {signUpModal && <SignUp 
        open={signUpModal}
        getUser={getUser}
        closeModal={() => setSignUpModal(false)} 
        openLogin={() => setLoginModal(true)} 
        state={state}
        toRoute={toRoute}
      />}
      {passwordReset && <PasswordReset 
        open={passwordReset}
        closeModal={() => setPasswordReset(false)}
        openLogin={() => setLoginModal(true)} 
      />}
      <div className='header-wrapper'>
        <div className='header'>
          <h1 className='logo'>mama mobile</h1>
          {Auth.id && 
            // <Button onClick={() => setLoginModal(true)} id='login-button' variant="outlined" color="secondary">log in</Button> :
            <Fab id='login-avatar' color="primary">
              <h5>
                {Auth.firstName && Auth.firstName.charAt(0)}
              </h5>
            </Fab>
          }
        </div>
      </div>
      <NotAvailable />
      {/* <CSSTransition 
        in={route['/']} 
        timeout={200} 
        classNames="card-transition"
        unmountOnExit
      >
        <Main handleSelect={selectStage} 
          title={["Welcome Mama! We'd like to learn a little bit more about you and introduce ourselves before getting your appointment scheduled."]} 
          question="What stage of mother-hood are you in?"
        />
      </CSSTransition>
      <CSSTransition 
        in={route['/select-service']}
        timeout={300} 
        classNames="card-transition"
        unmountOnExit
      >
        <SelectService 
          handleSelect={selectService} 
          question='What service are you looking for?' 
          type={type} 
        />
      </CSSTransition>
      <CSSTransition 
        in={route['/first-visit']}
        timeout={300} 
        classNames="card-transition"
        unmountOnExit
      >
        <FirstVisit handleSelect={selectFirstVisit} service={service} />
      </CSSTransition>
      <CSSTransition 
        in={route['/duration']}
        timeout={300} 
        classNames="card-transition"
        unmountOnExit
      >
        <Duration handleSelect={selectDuration} service={service} firstVisit={firstVisit} />
      </CSSTransition>
      <CSSTransition 
        in={route['/massage-addons']}
        timeout={300} 
        classNames="card-transition"
        unmountOnExit
      >
        <Addons handleSelect={selectAddons} duration={duration} type={type} />
      </CSSTransition>
      <CSSTransition 
        in={route['/physio/first-visit']}
        timeout={300} 
        classNames="card-transition"
        unmountOnExit
      >
        <PhysioFirstVisit setDuration={setDuration} />
      </CSSTransition>
      <CSSTransition 
        in={route['/physio/booking']}
        timeout={300} 
        classNames="card-transition"
        unmountOnExit
      >
        <PhysioBooking duration={duration} firstVisit={firstVisit} stage={type} />
      </CSSTransition>
      <CSSTransition 
        in={route['/physio/booking/consultation']}
        timeout={300}
        classNames="card-transition"
        unmountOnExit
      >
        <PhysioBooking duration={15} firstVisit={true} stage='prenatal' />
      </CSSTransition>
      <CSSTransition 
        in={route['/not-available']}
        timeout={300} 
        classNames="card-transition"
        unmountOnExit
      >
        <NotAvailable address={address} />
      </CSSTransition>
      <CSSTransition 
        in={route['/booking-request']}
        timeout={300} 
        classNames="card-transition"
        unmountOnExit
      >
        <RequestMassage address={address} type={type} service={service} duration={duration} addons={addons} user={USER.data && USER.data.user} />
      </CSSTransition>
      <CSSTransition 
        in={route['/address']}
        timeout={300} 
        classNames="card-transition"
        unmountOnExit
      >
        <Location handleSelect={selectAddress} setAddress={(value) => setAddress(value)} address={address} />
      </CSSTransition>
      <CSSTransition 
        in={route['/schedule']}
        timeout={300} 
        classNames="card-transition"
        unmountOnExit
      >
        <Schedule 
          service={service}
          openSignUp={() => setSignUpModal(true)}
          user={USER.data && USER.data.user} 
          address={address} 
          schedule={schedule}
          selectPro={pro => setPro(pro)}
          selectTime={time => setTime(time)}
          selectDateIdx={dateIdx => setDateIdx(dateIdx)}
          dateIdx={dateIdx}
          time={time}
          setToRoute={setToRoute}
        />
      </CSSTransition>
      <CSSTransition 
        in={route['/covid']}
        timeout={300} 
        classNames="card-transition"
        unmountOnExit
      >
        <Covid />
      </CSSTransition>
      <CSSTransition 
        in={route['/payment']}
        timeout={300} 
        classNames="card-transition"
        unmountOnExit
      >
        <Payment 
          user={USER.data && USER.data.user} 
          mainService={mainService} 
          addons={addons} 
          date={schedule[dateIdx] && schedule[dateIdx].date}
          time={time}
          pro={pro}
          service={service}
        />
      </CSSTransition>
      <CSSTransition 
        in={route['/request-confirmed']}
        timeout={300} 
        classNames="card-transition"
        unmountOnExit
      >
        <ThankYouRequest service={service} type={type} />
      </CSSTransition>
      <CSSTransition 
        in={route['/thank-you-booking']}
        timeout={300} 
        classNames="card-transition"
        unmountOnExit
      >
        <ThankYouBooking service={service} type={type} />
      </CSSTransition> */}
    </div>
  )
};

export default Landing;