const capitalize = (str) => {
  let result = str.trim();

  if (result.length === 0) {
    return;
  };

  result = result.charAt(0).toUpperCase() + result.slice(1).toLowerCase();
  return result;
};

export default capitalize;