import React, { useState, useContext } from 'react';
import { FirebaseContext } from '../../context';
import { Icon, Button } from '@material-ui/core';
import Loading from '../../components/Loading/Loading';
import Error from '../../components/Error/Error';

const styles = {
  svg: {
    height: '18px',
    width: '18px',
    fill: 'currentcolor',
    marginRight: '10px',
  },
};

const PasswordReset = ({ open, closeModal, openLogin }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [email, setEmail] = useState('');
  const Firebase = useContext(FirebaseContext);

  const resetPassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(false);
    try {
      await Firebase.passwordReset(email);
      setLoading(false);
      alert('Your password reset has been sent to your email - Please follow the instruction in your email to reset your password!');
    } catch (err) {
      setLoading(false);
      setError(err.message);
    }
  }

  return(
    <div className={open ? 'overlay' : 'hide'}>
      <div className='modal' id='password-reset-modal'>
        {loading ? <Loading /> : null}
        <Icon onClick={closeModal} id='close-modal-button' style={styles.icon}>close</Icon>
        <form className="auth-body">
          <h5>Reset your Password</h5>
          <p>Enter the email address associated with your account and we’ll email you a link to reset your password.</p>
          {error && <Error error={error} />}
          <label>Email</label>
          <input value={email} onChange={e => setEmail(e.target.value)} required type="email"/>
          <div className='auth-footer'>
            <div onClick={() => {closeModal(); openLogin()}} className='flex clickable'>
              <Icon style={{color: 'var(--color-grey-3)'}}>chevron_left</Icon>
              <h6>Back to Login</h6>
            </div>
            <Button 
              variant="contained"
              color="secondary"
              type="submit"
              onClick={resetPassword}
            >
              Send reset link
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default PasswordReset;