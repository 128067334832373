import React, { useState } from 'react';
import './notAvailable.css';
import { Button } from '@material-ui/core';
import emailError from '../../util/emailError';

const NotAvailable = ({ address }) => {
  const [firstName, setFirstName] = useState('');
  const [email, setEmail] = useState('');

  const handleSubmit = async e => {
    try {
      e.preventDefault();
  
      await fetch('https://api.mamamobile.com/sendgrid/emailWaitlist', {
        headers: {
          "Content-Type": "application/json"
        },
        method: 'POST',
        body: JSON.stringify({
          firstName,
          email,
          address: address.formatted_address
        }),
      });
      
      alert(`You are on our waitlist, we'll let you know as soon as we can when something opens up!`);
    } catch (err) {
      emailError(err.message, { message: 'waitlist error', firstName, email });
    }
  }
  return (
    <div className='landing-body' id='not-available'>
      <p>We apologize for the inconvenience, but we are currently unavailable.</p>
      {/* <p>No worries, just add your name to our waitlist 👇 and we will contact you as soon as a spot comes up!</p>
      <form onSubmit={handleSubmit}>
        <label>First Name</label>
        <input 
          value={firstName}
          onChange={e => setFirstName(e.target.value)}
          required
          name='firstName'
        />
        <label>Email</label>
        <input 
          value={email}
          onChange={e => setEmail(e.target.value)}
          required
          name='email'
        />
        <Button type='submit' variant="contained" color="secondary">Waitlist Me!</Button>
      </form> */}
    </div>
  )
};

export default NotAvailable;