import { gql } from 'apollo-boost';

const ADD_USER_WITH_RELATIONSHIP = gql`
  mutation AddUserWithRelationship(
    $user: UserInput
    $relationship: RelationshipInput
  ) {
    addUserWithRelationship(
      user: $user
      relationship: $relationship
    ) {
      success
      message
      user {
        id
      }
    }
  }
`;

export default ADD_USER_WITH_RELATIONSHIP;