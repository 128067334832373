import { gql } from 'apollo-boost';

const ADD_BOOKING = gql`
  mutation AddBooking(
    $id: ID
    $date: String
    $startTime: Int
    $duration: Int
    $subtotal: Float
    $tax: Float
    $tips: Float
    $total: Float
    $bookingSource: String
    $services: [ServicesInput]
    $notes: String
    $proAmount: Float
    $promoId: ID
    $promoValue: Int
    $giftCardId: ID
    $proId: ID!
    $stripe: String
    $userId: ID!
    $users: [ID]
    $paymentId: String
    $paymentStatus: PaymentStatus
  ) {
    addBooking(
      id: $id
      date: $date
      startTime: $startTime
      duration: $duration
      subtotal: $subtotal
      tax: $tax
      tips: $tips
      total: $total
      bookingSource: $bookingSource
      services: $services
      notes: $notes
      proAmount: $proAmount
      promoId: $promoId
      promoValue: $promoValue
      giftCardId: $giftCardId
      proId: $proId
      stripe: $stripe 
      userId: $userId
      users: $users
      paymentId: $paymentId
      paymentStatus: $paymentStatus
    ) {
      success
      message
      booking {
        id
        startTime
        date
        subtotal
        tax
        total
        paymentStatus
        duration
      }
      schedule {
        id
        date
        time
        status
        booking {
          id
        }
      }
    }
  }
`;

export default ADD_BOOKING;